import React, { useState } from 'react';

function Summary({ handleClose }) {
  const [step, setStep] = useState(1); // Шаг 1 - выбор типа помещения, Шаг 2 - выбор типа ремонта, Шаг 3 - выбор стиля ремонта, Шаг 4 - указание размера помещения
  const [formData, setFormData] = useState({
    type: '',
    repairType: '',
    style: '',
    size: '',
    name: '',
    phone: ''
  });

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSendData = async () => {
    try {
      const response = await fetch('https://66fa3c8cafc569e13a9ad7f7.mockapi.io/api/jaksystroy/customers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert('Данные успешно отправлены!');
      } else {
        alert('Произошла ошибка при отправке данных');
      }
    } catch (error) {
      console.error('Ошибка:', error);
      alert('Произошла ошибка при отправке данных');
    }
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-50 bg-gray-800">
      <div className="bg-white p-8 rounded-lg">
        {step === 1 && (
          <>
            <div className='flex flex-row justify-center items-center'>
              <div className='container flex flex-col lg:flex-row items-center py-4 px-4' style={{ backgroundColor: '#3e3e64' }}>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-200" onClick={() => {
                  setFormData({ ...formData, type: 'Квартира' });
                  handleNextStep();
                }}>
                  <img src="./assets/flat.jpeg" alt="Квартира" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Квартира</p>
                </div>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, type: 'Жилой дом' });
                  handleNextStep();
                }}>
                  <img src="./assets/house.jpg" alt="Жилой дом" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Жилой дом</p>
                </div>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, type: 'Коммерческое помещение' });
                  handleNextStep();
                }}>
                  <img src="./assets/office.jpeg" alt="Коммерческое помещение" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Коммерческое <br />помещение</p>
                </div>
              </div>
              <div className="ml-8 max-w-xs">
                <div>
                  <h2 className="font-medium text-3xl mb-2 lg:text-left" style={{ color: '#4A4A4A' }}>ТИП ПОМЕЩЕНИЯ</h2>
                  <p className="text-sm font-light lg:text-left" style={{ color: '#4A4A4A' }}>Мы внимательно подойдем к выбору материалов и планировке, чтобы создать оптимальные условия для использования пространства</p>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 2 && (
          <>
            <div className='flex flex-row justify-center items-center'>
              <div className='container flex flex-col lg:flex-row items-center py-4 px-4' style={{ backgroundColor: '#3e3e64' }}>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, repairType: 'Капитальный' });
                  handleNextStep();
                }}>
                  <img src="./assets/capital.jpeg" alt="Капитальный" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Капитальный</p>
                </div>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, repairType: 'Косметический' });
                  handleNextStep();
                }}>
                  <img src="./assets/cosmetica.jpg" alt="Косметический" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Косметический</p>
                </div>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, repairType: 'Дизайнерский' });
                  handleNextStep();
                }}>
                  <img src="./assets/desinger.jpeg" alt="Дизайнерский" className="w-48 h-36 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Дизайнерский</p>
                </div>
              </div>
              <div className="ml-8 max-w-xs">
                <div>
                  <h2 className="font-medium text-3xl mb-2 lg:text-left" style={{ color: '#4A4A4A' }}>ТИП РЕМОНТА</h2>
                  <p className="text-sm font-light lg:text-left" style={{ color: '#4A4A4A' }}>Описание типов ремонта...</p>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 3 && (
          <>
            <div className="flex flex-row justify-center items-center">
              <div className="container flex flex-col lg:flex-row items-center py-4 px-4" style={{ backgroundColor: '#3e3e64' }}>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, style: 'Хай-тек' });
                  handleNextStep();
                }}>
                  <img src="./assets/haitec.jpeg" alt="Хай-тек" className="w-36 h-24 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Хай-тек</p>
                </div>
                <div className="rounded-lg bg-gray-100 pb-4 mr-4 cursor-pointer hover:bg-purple-300" onClick={() => {
                  setFormData({ ...formData, style: 'Неоклассика' });
                  handleNextStep();
                }}>
                  <img src="./assets/neoclassica.jpeg" alt="Неоклассика" className="w-36 h-24 rounded-t-lg" />
                  <p className="text-center mt-4 text-sm">Неоклассика</p>
                </div>
              </div>
              <div className="ml-8 max-w-xs">
                <div>
                  <h2 className="font-medium text-3xl mb-2 lg:text-left" style={{ color: '#4A4A4A' }}>СТИЛЬ РЕМОНТА</h2>
                  <p className="text-sm font-light lg:text-left" style={{ color: '#4A4A4A' }}>Описание стилей...</p>
                </div>
              </div>
            </div>
          </>
        )}

        {step === 4 && (
          <>
            <h2 className="font-medium text-3xl mb-2 lg:text-left" style={{ color: '#4A4A4A' }}>РАЗМЕР ПОМЕЩЕНИЯ</h2>
            <input
              type="number"
              name="size"
              value={formData.size}
              onChange={handleInputChange}
              className="custom-placeholder p-2 w-full border rounded-lg mt-4"
              placeholder="Укажите размер площади предполагаемого помещения"
            />
          </>
        )}

        {step === 5 && (
          <>
            <h2 className="font-medium text-3xl mb-2 lg:text-left" style={{ color: '#4A4A4A' }}>Расчет стоимости</h2>
            <p className="text-md lg:text-left" style={{ color: '#4A4A4A' }}>Мы подготовили для вас предварительный расчет стоимости ремонта.</p>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              className="custom-placeholder p-2 w-full border rounded-lg mt-4"
              placeholder="Ваше имя"
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              className="custom-placeholder p-2 w-full border rounded-lg mt-4"
              placeholder="Ваш телефон"
            />
            <button
              onClick={handleSendData}
              className="inline-block mt-8 ml-8 text-white font-bold border px-14 py-2 rounded-full hover:text-white transition"
              style={{ background: '#313155', borderColor: '#313155' }}
            >
              Отправить
            </button>
          </>
        )}

        {step > 1 && step < 5 && (
          <div className="flex justify-between mt-8">
            <button
              onClick={handlePreviousStep}
              className="inline-block text-white font-bold border px-14 py-2 rounded-full hover:text-white transition"
              style={{ background: '#313155', borderColor: '#313155' }}
            >
              Назад
            </button>
            <button
              onClick={handleNextStep}
              className="inline-block text-white font-bold border px-14 py-2 rounded-full hover:text-white transition"
              style={{ background: '#313155', borderColor: '#313155' }}
            >
              Далее
            </button>
          </div>
        )}

        <button
          onClick={handleClose}
          className="mt-4 text-gray-700 hover:text-gray-900 underline"
        >
          Закрыть
        </button>
      </div>
    </div>
  );
}

export default Summary;
