import React, { useState } from "react";
import { Link } from 'react-router-dom';

function NavBar(){
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return(
        <div className="Navbar">
            <nav style={{ backgroundColor: '#fdfdfd' }} className="navbar-shadow fixed w-full z-10 top-0 start-0">
                <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
                    <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src="./assets/logo1.png" className="h-12 mt-2" alt="Jaksy Stroy LB Logo"></img>
                    </a>
                    <div className="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                    <div className="flex flex-col md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse items-center">
                        {/* Добавляем город сверху и номер телефона снизу */}
                        <span className="text-gray-1000 text-lg font-semibold dark:text-white"><i className="fas fa-map-marker-alt mr-2"></i>Алматы</span> 
                        <a className=" text-lg text-gray-1000 font-light rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-900">
                            +7 (776) 784 90 00
                        </a>
                    </div>
                        <button onClick={toggleMenu} type="button" className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-sticky" aria-expanded={isMenuOpen}>
                            <span className="sr-only">Open main menu</span>
                            <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15"/>
                            </svg>
                        </button>
                    </div>
                    <div className={`items-center justify-center w-full md:flex md:w-auto md:order-1 ${isMenuOpen ? 'block' : 'hidden'}`} id="navbar-sticky">
                        <ul className="nav-menu flex flex-col p-4 md:p-0 mt-4 font-medium rounded-lg md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0">
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700" style={{ color: '#3e3e64', fontWeight: 700 }} aria-current="page">
                                <Link to="/" className="">Главная</Link>
                            </li>
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                <Link to="/list" className="">Услуги</Link>
                            </li>
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                <Link to="/prices" className="">Расценки</Link>
                            </li>
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                <Link to="/gallery" className="">Галерея</Link>
                            </li>
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                <Link to="/review" className="">Отзывы</Link>
                            </li>
                            <li className="nav-item py-8 px-6 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-hoverColor md:p-0 md:dark:hover:text-hoverColor dark:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700">
                                <Link to="/blog" className="">Блог</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default NavBar;
