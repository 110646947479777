import React from "react";
import { FaFacebookF, FaInstagram, FaTwitter, FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from 'react-icons/fa';

function Footer(){
    return(
        <footer className="py-10 text-white" style={{ backgroundColor: '#3e3e64' }}>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {/* Логотип и описание компании */}
            <div className="text-center md:text-left">
              <h2 className="text-2xl font-bold mb-4">Эксперт ремонта</h2>
              <p className="text-gray-400">
                Профессиональные услуги ремонта и дизайна. Мы делаем ремонт, на который можно положиться. 
                Качество, прозрачность и забота о клиентах — это наш приоритет.
              </p>
            </div>
  
            {/* Быстрые ссылки */}
            <div className="text-center md:text-left">
              <h3 className="text-xl font-semibold mb-4">Быстрые ссылки</h3>
              <ul>
                <li className="mb-2">
                  <a href="#about" className="hover:text-gray-300 transition">О нас</a>
                </li>
                <li className="mb-2">
                  <a href="#services" className="hover:text-gray-300 transition">Наши услуги</a>
                </li>
                <li className="mb-2">
                  <a href="#offers" className="hover:text-gray-300 transition">Специальные предложения</a>
                </li>
                <li className="mb-2">
                  <a href="#faq" className="hover:text-gray-300 transition">FAQ</a>
                </li>
                <li>
                  <a href="#contact" className="hover:text-gray-300 transition">Контакты</a>
                </li>
              </ul>
            </div>
  
            {/* Контактная информация */}
            <div className="text-center md:text-left">
              <h3 className="text-xl font-semibold mb-4">Контакты</h3>
              <ul>
                <li className="mb-2 flex items-center">
                  <FaPhoneAlt className="mr-2" /> 
                  <span>+7 7767849000</span>
                </li>
                <li className="mb-2 flex items-center">
                  <FaEnvelope className="mr-2" /> 
                  <span>jaksystroy@gmail.com</span>
                </li>
                <li className="flex items-center">
                  <FaMapMarkerAlt className="mr-2" /> 
                  <span>г. Алматы, Жетису-3 мкрн., 25a</span>
                </li>
              </ul>
            </div>
          </div>
  
          {/* Социальные сети */}
          <div className="mt-10 text-center md:text-left">
            <h3 className="text-xl font-semibold mb-4">Мы в соцсетях</h3>
            <div className="flex justify-center md:justify-start space-x-6">
              <a href="#" className="text-white hover:text-gray-400 transition">
                <FaFacebookF size={24} />
              </a>
              <a href="#" className="text-white hover:text-gray-400 transition">
                <FaInstagram size={24} />
              </a>
              <a href="#" className="text-white hover:text-gray-400 transition">
                <FaTwitter size={24} />
              </a>
            </div>
          </div>
  
          {/* Копирайт */}
          <div className="mt-10 text-center text-gray-400">
            <p>&copy; 2024 JaksyStroyLB. Все права защищены.</p>
          </div>
        </div>
      </footer>
    );
  }

export default Footer;