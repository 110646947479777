import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Modal from 'react-modal';
import { FaExpand, FaImages } from 'react-icons/fa';

const Gallery = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedApartment, setSelectedApartment] = useState(null);
  const [fullImage, setFullImage] = useState(null);

  const galleryData = [
    {
      id: 1,
      thumbnail: './assets/home1/home1.jpeg',
      title: 'Квартира 1',
      images: ['./assets/home1/home1.jpeg', './assets/home1/home1.1.jpeg', './assets/home1/home1.2.jpeg', './assets/home1/home1.3.jpeg', './assets/home1/home1.4.jpeg']
    },
    {
      id: 2,
      thumbnail: './assets/home2/home2.jpeg',
      title: 'Квартира 2',
      images: ['./assets/home2/home2.jpeg', './assets/home2/home2.1.jpeg', './assets/home2/home2.2.jpeg', './assets/home2/home2.3.jpeg', './assets/home2/home2.4.jpeg']
    },
    {
      id: 3,
      thumbnail: './assets/home3.jpeg',
      title: 'Квартира 3'
    },
    {
      id: 4,
      thumbnail: './assets/home4.jpeg',
      title: 'Квартира 4'
    },
    // Остальные квартиры
    {
      id: 5,
      thumbnail: './assets/home5.jpeg',
      title: 'Квартира 5'
    },
    {
      id: 6,
      thumbnail: './assets/home6.jpeg',
      title: 'Квартира 6'
    },
    {
      id: 7,
      thumbnail: './assets/home7.jpeg',
      title: 'Квартира 7'
    },
    {
      id: 8,
      thumbnail: './assets/home8.jpeg',
      title: 'Квартира 8'
    },
    {
      id: 9,
      thumbnail: './assets/home9.jpeg',
      title: 'Квартира 9'
    },
    {
      id: 10,
      thumbnail: './assets/home10.jpeg',
      title: 'Квартира 10'
    },
    {
      id: 11,
      thumbnail: './assets/home11.jpeg',
      title: 'Квартира 11'
    },
    {
      id: 12,
      thumbnail: './assets/home12.jpeg',
      title: 'Квартира 12'
    },
    {
      id: 13,
      thumbnail: './assets/home13.jpeg',
      title: 'Квартира 13'
    }
  ];

   const openModal = (apartment) => {
    if (apartment.images) {
      setSelectedApartment(apartment);
      setIsModalOpen(true);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedApartment(null);
    setFullImage(null);
  };

  const openFullImage = (image) => {
    setFullImage(image);
    setIsModalOpen(true);
  };

  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-5xl font-medium text-left mb-12" style={{ color: '#3e3e64' }}>Галерея</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {galleryData.map(apartment => (
            <motion.div
              key={apartment.id}
              className="relative rounded-lg overflow-hidden cursor-pointer"
              whileHover={{ scale: 1.05 }}
              onClick={() => openModal(apartment)}
            >
              <img src={apartment.thumbnail} alt={apartment.title} className="w-full h-64 object-cover" />
              {apartment.images ? (
                <div className="absolute top-2 right-2 text-white">
                  <FaImages size={24} />
                </div>
              ) : (
                <div className="absolute top-2 right-2 text-white">
                  <FaExpand size={24} onClick={() => openFullImage(apartment.thumbnail)} />
                </div>
              )}
            </motion.div>
          ))}
        </div>

        {/* Модальное окно для квартир с набором фото */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="bg-white p-8 rounded-lg w-4/5 max-w-3xl mx-auto mt-16"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          {selectedApartment && (
            <>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                {selectedApartment.images ? (
                  selectedApartment.images.map((image, index) => (
                    <motion.img
                      key={index}
                      src={image}
                      alt={`Фото ${index + 1}`}
                      className="w-full h-64 object-cover rounded-lg cursor-pointer"
                      onClick={() => openFullImage(image)}
                      whileHover={{ scale: 1.05 }}
                    />
                  ))
                ) : (
                  <img
                    src={selectedApartment.thumbnail}
                    alt={selectedApartment.title}
                    className="w-full h-64 object-cover rounded-lg"
                  />
                )}
              </div>
            </>
          )}
          {fullImage && (
            <Modal
              isOpen={true}
              onRequestClose={() => setFullImage(null)}
              className="bg-white p-8 rounded-lg w-4/5 max-w-3xl mx-auto mt-16"
              overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
            >
              <img
                src={fullImage}
                alt="Увеличенное фото"
                className="w-full  object-cover" // Ограничение по высоте
              />
            </Modal>
          )}
        </Modal>
      </div>
    </section>
  );
};

export default Gallery;