import React, { useState } from 'react';
import Modal from 'react-modal';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

const Reviews = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  const [newReview, setNewReview] = useState({ name: '', review: '', rating: 5 });
  const [reviews, setReviews] = useState([]);
  const [loading, setLoading] = useState(true); // Состояние загрузки
  const [error, setError] = useState(null); // Состояние ошибки

  const API_ENDPOINT = 'https://66fa3c8cafc569e13a9ad7f7.mockapi.io/api/jaksystroy/reviews';

  // Функция для загрузки отзывов с MockAPI
  const fetchReviews = async () => {
    try {
      const response = await fetch(API_ENDPOINT);
      if (!response.ok) {
        throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
      }
      const data = await response.json();
      setReviews(data);
    } catch (err) {
      console.error('Ошибка при загрузке отзывов:', err);
      setError('Не удалось загрузить отзывы. Пожалуйста, попробуйте позже.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchReviews();
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Валидация полей
    if (!newReview.name.trim() || !newReview.review.trim()) {
      alert('Пожалуйста, заполните все поля.');
      return;
    }

    try {
      const response = await fetch(API_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newReview),
      });

      if (!response.ok) {
        throw new Error(`Ошибка: ${response.status} ${response.statusText}`);
      }

      const addedReview = await response.json();
      setReviews([addedReview, ...reviews]); // Добавляем новый отзыв в начало списка
      alert('Ваш отзыв успешно отправлен!');
      closeModal();
    } catch (err) {
      console.error('Ошибка при отправке отзыва:', err);
      alert('Произошла ошибка при отправке отзыва. Пожалуйста, попробуйте позже.');
    }
  };
  const reviewsData = [
    {
      id: 1,
      name: 'Жанара',
      review: 'Хотела заказать встроенный шкаф купе с детской комнатой все на заказ в фирме ******* так как она самая разрекламированная и популярная в Астане и ценник тоже соответствовал ее статусу просто космос как я узнала позднее. Подруга убедила обратиться в данную компанию эксперт ремонта сказав отнеси дизайн пусть посчитают мы там заказывали кухню и ситуация была подобная сделали на уровне и придраться не к чему. Цена в данной компании оказалась более чем щадящей без кредитов и рассрочек смогли позволить. Благодарю за исполнение компанию в особенности Андрея что позволил отложить оплату до снятия вклада и Елену за доработку деталей. Заказом довольны полностью.',
      rating: 5,
    },
    {
      id: 2,
      name: 'Айгуль',
      review: 'Замечательная компания, отличные работники! Особая благодарность Александру, который все наши предложения грамотно воплотил в жизнь, постоянно отчитывался о работе. Объем с нуля под ключ быстро и качественно. Особенность компании - отношение с клиентом идеальное, без перекладывания на него проблем, а их решение. Спасибо, Вы лучшие!',
      rating: 5,
    },
    {
      id: 3,
      name: 'Смирнова Д.С      ',
      review: 'Считала ремонт квартиры на сайте в калькуляторе неожиданно позвонили после этого и предложили рассчитать детально, создать проект кухни, электрики, планировки и освещения если стоимость не устроит сказав - то выполним в рассрочку. Угодили полностью качество высокое, предложения Александра и Толгата о деталях в интерьере с дизайном мебели и расстановке выше всех ожиданий, в том виде как обслуживают клиентов еще не встречала компаний с ними возможно многое',
      rating: 5,
    },
    {
      id: 4,
      name: 'Елена и Ирина Семёновы      ',
      review: 'От чистого сердца хотим выразить благодарность компании "Jaksy Stroy"! Пожалуй, каждый из Вас кто сейчас читает отзыв, думает о том, в какую же компанию им обратиться, так вот…вне всяких раздумий и сомнений можете доверить свой ремонт ребятам именно из этой компании! Мы с дочерью заказывали ремонт ванной комнаты и туалета, из плюсов можем отметить, что все ремонтные работы были выполнены на совесть, а ремонт закончен даже раньше указанного в договоре срока. По завершению ремонта понимаем, что соотношение цены и качества полностью себя оправдало. Результат превзошёл все наши ожидания, до сих пор не можем поверить, что задуманная идея ремонта осуществилась. Также отдельно хотим выразить свою благодарность Александру за индивидуальный подход и внимание к нам как клиентам, ответственность, порядочность и оперативное решение вопросов, а также мастеру Александру – у Вас просто золотые руки! Однозначно будем обращаться ещё!',
      rating: 5,
    },
    {
      id: 5,
      name: 'Жанна      ',
      review: 'Мне нужно было сдать квартиру и требовался ремонт в гостиной. Обратилась в компанию, обозначила, что дело срочное и дешевое. Сделали за неделю! Такие молодцы! Жильцы живут уже целый год, не одной жалобы на состояние квартиры нет! Огромное спасибо за понимание! обратилась ещё)',
      rating: 5,
    },
    {
      id: 6,
      name: 'Алевтина Сергеевна      ',
      review: 'Спасибо большое за ремонт моей новой квартиры. Понравилось сотрудничать исполнительность и качество на высоте',
      rating: 5,
    },
    {
      id: 7,
      name: 'Сергей      ',
      review: 'Мне нужен был ремонт ванной комнаты.В инете нашел ваш сайт,решил позвонить.Честно сказать я обомлел,так оперативно мне еще ремонт никто не делал!В этот же день приехал инженер сделал замер,созвонились-цена устроила.На следующий день во всю кипела работа.Класс,за неделю все было готово!Искрение вам спасибо,с уважением Сергей Николаевич Смолин',
      rating: 5,
    }
  ];

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewReview({ name: '', review: '', rating: 5 }); // Сброс формы
  };


  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-5xl font-medium text-center mb-4" style={{ color: '#3e3e64' }}>
          Отзывы наших клиентов
        </h2>
        <button 
          onClick={openModal} 
          className="mb-6 px-6 py-2 bg-purple-900 text-white rounded-lg hover:bg-purple-800 transition duration-300"
        >
          Оставить отзыв
        </button>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {reviewsData.map((review) => (
            <motion.div 
              key={review.id} 
              className="bg-white p-6 rounded-lg shadow-lg"
              initial={{ scale: 1 }} 
              whileHover={{ scale: 1.05 }} 
              transition={{ duration: 0.3 }}
            >
              <h3 className="text-xl font-semibold mb-2">{review.name}</h3>
              <p className="text-lg font-light mb-4">{review.review}</p>
              <div className="flex items-center">
                {[...Array(review.rating)].map((_, index) => (
                  <span key={index} className="text-yellow-500">&#9733;</span>
                ))}
              </div>
            </motion.div>
          ))}
        </div>

        {/* Модальное окно для отправки отзыва */}
        <Modal
          isOpen={isModalOpen}
          onRequestClose={closeModal}
          className="bg-white p-8 rounded-lg w-4/5 max-w-md mx-auto mt-16"
          overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        >
          <h2 className="text-xl font-semibold mb-4">Оставить отзыв</h2>
          <form onSubmit={handleSubmit}>
            <input 
              type="text" 
              placeholder="Ваше имя" 
              className="w-full p-2 border border-gray-300 rounded mb-4" 
              value={newReview.name}
              onChange={(e) => setNewReview({ ...newReview, name: e.target.value })}
              required
            />
            <textarea 
              placeholder="Ваш отзыв" 
              className="w-full p-2 border border-gray-300 rounded mb-4" 
              value={newReview.review}
              onChange={(e) => setNewReview({ ...newReview, review: e.target.value })}
              required
            />
            <button type="submit" className="w-full bg-purple-900 text-white rounded py-2 hover:bg-purple-800 transition duration-300">
              Отправить
            </button>
          </form>
          <button 
            className="mt-4 px-6 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-700 transition duration-300" 
            onClick={closeModal}
          >
            Закрыть
          </button>
        </Modal>
      </div>
    </section>
  );
};

export default Reviews;