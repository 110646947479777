import React,{ useState } from "react";
import { Link } from "react-router-dom";
import Testimonials from "../components/Testimonials";
import Summary from "../components/Summary";
import { motion } from 'framer-motion';
import { InformationCircleIcon } from '@heroicons/react/outline';
import Modal from 'react-modal';
import Chatbot from "../components/ChatBot";

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      padding: '40px',
      backgroundColor: '#f0f0f0',
      borderRadius: '10px',
      width: '80%',
      maxWidth: '600px',
    },
  };

  Modal.setAppElement('#root');

function Main() {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };

    const [activeIndex, setActiveIndex] = useState(null);
    // first slider
    const [sliderPosition, setSliderPosition] = useState(45);
    const [isDragging, setIsDragging] = useState(false);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };
    

    const handleMove = (event) => {
        if (!isDragging) return;

        const rect = event.currentTarget.getBoundingClientRect();
        const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
        const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));

        setSliderPosition(percent);
    };

    const handleMouseDown = () => {
        setIsDragging(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    }

    // second slider
    const [slider2Position, setSlider2Position] = useState(45);
    const [isDragging2, setIsDragging2] = useState(false);

    const handleMove2 = (event) => {
        if (!isDragging2) return;

        const rect = event.currentTarget.getBoundingClientRect();
        const x = Math.max(0, Math.min(event.clientX - rect.left, rect.width));
        const percent = Math.max(0, Math.min((x / rect.width) * 100, 100));

        setSlider2Position(percent);
    };

    const handleMouseDown2 = () => {
        setIsDragging2(true);
    };

    const handleMouseUp2 = () => {
        setIsDragging2(false);
    }



    const faqs = [
        {
          question: 'Как производится оплата ремонта квартиры?',
          answer: 'Без предоплаты: подписание актов о выполненных работах на каждом этапе. Предоплата за материалы: если закупка материалов ложится на нашу компанию, клиент вносит символическую сумму на счёт в нашей компании. Мы предоставляем отчёт о приобретённых товарах по мере закупки.'
        },
        {
          question: 'Сколько стоит ремонт квартиры?',
          answer: 'Так как компания работает без предоплаты и за простои не платят, на объекте отсутствуют простои полностью, а срок выполнения работ для заказчика оптимален. Простои могут быть допущены исключительно для создания технологических условий для разных групп применяемых материалов.Более подробно за данным вопросом вы можете записаться на замер по телефону компании.'
        },
        {
          question: 'Что такое гарантийное обслуживание?',
          answer: 'После выполнения всех работ и получения окончательного расчета компания выполняет обслуживание всех выполненных работ у клиента, как правило это касается проведенных коммуникаций электроснабжения и водоснабжения но и другие детали ремонта попадают под гарантию и обслуживание в случае если работы выполнялись под ключ с согласованной закупкой материалов с инженером компании. Заскрипели полы и двери, отошли обои, забились фильтры грубой очистки и т.д, мы все поправим достаточно позвонить специалист выедет незамедлительно или в удобное для вас время. Все работы по устранению недостатков компания выполняет за свой счет.'
        },
        {
          question: 'Как оставить заявку?',
          answer: 'В вашем распоряжении любое средство связи: интернет, телефон или личная встреча в офисе компании. Заявка не относится к договору или каким-либо другим обязательствам. Она всего лишь уведомит работников о Вашем желании вызвать бесплатного замерщика для консультации.'
        },
        {
        question: 'Как оставить заявку?',
        answer: 'В вашем распоряжении любое средство связи: интернет, телефон или личная встреча в офисе компании. Заявка не относится к договору или каким-либо другим обязательствам. Она всего лишь уведомит работников о Вашем желании вызвать бесплатного замерщика для консультации.'
        },
        {
            question: 'Когда осуществляется замер и для чего он нужен?',
            answer: 'Замер производится в день подачи заявки, либо в другое удобное для Вас время, даже вечером. Его выполнит работник компании, он ответит на все Ваши вопросы, сориентирует в стоимости и подскажет наиболее удачные решения именно для Вашего ремонта. Предварительный замер необходим для составления сметы, он ни к чему Вас не обязывает.'
        },
        {
        question: 'Что такое смета и для чего она нужна?',
        answer: 'Смета составляется на основе замера и полностью готова в этот же день. Она наглядно покажет Вам – какие материалы потребуются, для чего они нужны, каков их расход и стоимость. Смета составляется единожды и остаётся неизменной на протяжении всего ремонта. После её утверждения вы можете ознакомиться с договором на выполнение работ.'
        },
        {
        question: 'Какой заключается договор и какие он дает гарантии?',
        answer: 'Договор выполнения ремонтных работ является услугой подряда. Он юридически весом и служит Вашим абсолютным гарантом выполнения обязательств со стороны фирмы-исполнителя. Договор содержит в себе сроки, суммы и перечень обязанностей участников – всё по стандартам. После заключения договора производится предоплата, она также обусловлена договором.'
        },
        {
        question: 'Как закупаются строительные материалы?',
        answer: 'Список материалов, составленный сотрудником, отправляется в магазины города. Это позволяет выбрать лучший вариант покупки по соотношению “Цена-Качество”. Когда настанет момент для покупки элементов стиля и декора, специалист компании проводит Вас в магазин и проконсультирует о надежности и пригодности, показав качественные материалы, либо мы приобретем строительные материалы сами предоставив вам подробный отчет. Каждый клиент компании получает ОПТОВЫЕ СКИДКИ на все приобретенные материалы.'
        },
        {
            question: 'Кто будет заниматься ремонтом и когда?',
            answer: 'Выполнение ремонтных работ начинается незамедлительно – сразу после покупки всех необходимых материалов. Ваш ремонт выполнят трезвые, казахскоговорящие и русскоговорящие мастера имеющие большой опыт работы. Фирма выборочно сформирует бригаду узкой специализации, чьи навыки пригодятся именно для Вашего объекта. Все мастера работают уже давно и приучены выполнять работу ответственно, качественно и чисто.'
        },
        {
        question: 'Как происходит сдача объекта?',
        answer: 'После выполнения всего спектра работ, прописанных в договоре, мастера делают уборку и полностью подготавливают объект к сдаче. В конце работы Вам предоставляется ещё одна полная отчетность в документальном виде. Только после того, как вы утвердите приём объекта, вносится окончательная оплата'
        },
        {
            question: 'Как производится окончательный расчет и какова его сумма?',
            answer: 'Сумма, которая вноситься окончательным платежом обусловлена договором. Расчёт происходит только после Вашего подробного осмотра. Выплата денежных средств производится по окончанию всех работ, выплачивается только если Ваши требования выполнены в полной мере и замечаний к сотрудникам не имеется.'
        }
      ];
    
      const offers = [
        {
          title: 'Скидка 10% на первый заказ',
          description: ' Пожелавших переехать в Алматы ждет теплый прием и большая скидка',
          longDescription: 'Тем, кто мечтает о собственном доме или квартире в Алматы, стоит обратить внимание на то что компания всегда рада помочь не только скидкой 10% и последующим ремонтом и выбором района и новостройки в городе, рассказав все преимущества и недостатки от территориальных до локальных и почерка застройщика. Переезжаем в город Нур Султан – Вас ждет теплый прием и помощь компании во всем! Напомним что мы можем работать удаленно отчитываясь видео и фото отчетами о проделанной работе.',
          image: './assets/offer1.webp',
          bgColor: '#cec9e0',
        },
        {
          title: 'Натяжной потолок в подарок!',
          description: 'Ремонт квартиры под ключ - Натяжной потолок в подарок!          ',
          longDescription: 'При заказе ремонта квартиры под ключ компания предоставляет всем своим клиентам натяжной потолок производства Германии любого цвета и фактуры БЕСПЛАТНО во все ремонтируемые помещения, не имеет значения новостройка либо вторичное жилье все равно Бесплатно!',
          image: './assets/offer2.png',
          bgColor: '#cec9e0',
        },
        {
          title: 'Скидка от 10 до 20%',
          description: 'Пенсионерам и инвалидам 1-2 группы, военным в отставке Скидка от 10 до 20%',
          longDescription: '"Jaksy Stroy" предлагает специальные скидки для пенсионеров, инвалидов 1-2 групп и военных в отставке в Алматы. Эта инициатива направлена на обеспечение доступности и комфорта в ремонтных работах для лиц, нуждающихся в дополнительной поддержке. Скидка охватывает широкий спектр услуг, от косметического ремонта до комплексной реконструкции, с целью создания безопасной и удобной домашней среды. "Jaksy Stroy" стремится к социальной ответственности и улучшению качества жизни всех жителей города.',
          image: './assets/offer3.jpeg',
          bgColor: '#cec9e0',
        },
      ];

      const [modalIsOpen, setsIsOpen] = useState(false);
        const [selectedOffer, setSelectedOffer] = useState(null);

        function openModal(offer) {
            setSelectedOffer(offer);
            setsIsOpen(true);
        }

        function closeModal() {
            setsIsOpen(false);
            setSelectedOffer(null);
        }


    return (
        <div>
            <section
            style={{
                backgroundImage: 'url(./assets/back.png)',
                backgroundSize: 'cover',
                position: 'relative',
                paddingBottom: '36px',
            }}
            className="py-24 flex flex-col items-center"
            >
            <div className="container text-center px-6">
                {/* Заголовок с анимацией */}
                <motion.h1
                    className="text-6xl text-white font-bold mb-6 font-montserrat text-center drop-shadow-lg"
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                    >
                    Эксперт ремонта <br />
                    <span className="text-lg text-gray-300">Без предоплаты с полной прозрачностью</span>
                </motion.h1>
                {/* Карточки с преимуществами */}
                <div className="flex justify-center">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 max-w-5xl">
                        {[...Array(6)].map((_, index) => (
                        <motion.div
                            key={index}
                            className="bg-white bg-opacity-90 p-6 rounded-lg shadow-lg transition-transform duration-300 hover:shadow-xl"
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            whileHover={{ y: -10 }} // Двигаем карточку вверх на 10 пикселей при наведении
                        >
                            <div className="flex items-center mb-4">
                            <InformationCircleIcon className="h-12 w-12 text-purple-700" />
                            <h2 className="ml-2 text-lg font-semibold text-gray-900">
                                {index === 0 && 'Бесплатный выезд замерщика в день обращения'}
                                {index === 1 && 'Проф консультации от замера и дизайна до закупки материалов'}
                                {index === 2 && 'Быстрый подробный расчет сметы в течении суток'}
                                {index === 3 && 'Документальная гарантия качества всех выполненных работ'}
                                {index === 4 && 'Работаем без предоплаты, оплата после выполнения работ'}
                                {index === 5 && 'Выгодные цены оптовиков на закупки стройматериалов для клиентов'}
                            </h2>
                            </div>
                            <p className="text-gray-600">
                            {index === 0 && 'Получите выезд замерщика в удобное для вас время.'}
                            {index === 1 && 'Консультации по всем вопросам по дизайну и материалам.'}
                            {index === 2 && 'Смета будет готова в течение суток.'}
                            {index === 3 && 'Мы гарантируем качество всех выполненных работ.'}
                            {index === 4 && 'Оплата производится после выполнения работ.'}
                            {index === 5 && 'Снижаем цены на закупку стройматериалов для клиентов.'}
                            </p>
                        </motion.div>
                        ))}
                    </div>
                    </div>

                {/* Кнопка Подробнее */}
                
            </div>
            </section>

            <section className="py-5 bg-gray-100">
            <div className="container mx-auto mt-16">
                <h2 className="text-5xl font-semibold text-center mb-4" style={{ color: '#4A4A4A' }}>Почему выбирают нас?</h2>
                <h4 className="text-2xl font-light text-center mb-8" style={{ color: '#5A5A5A' }}>
                МЫ ПРЕДЛАГАЕМ ЛУЧШИЕ СЕРВИСЫ <br /> ПО ЛУЧШЕЙ ЦЕНЕ
                </h4>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-12 sm:p-12">
                {[{ number: '01', title: 'Специализация под ключ', text: 'Мы готовы выполнить самые нестандартные задачи и удовлетворить самые изысканные потребности наших клиентов.' },
                    { number: '02', title: 'Более 3 лет опыта работы', text: 'Мы обладаем более чем тремя годами успешного опыта в сфере ремонта и строительства.' },
                    { number: '03', title: 'Более 2000 довольных клиентов', text: 'Наши услуги уже оценили более двух тысяч клиентов, которые остались довольны результатами нашей работы.' }
                ].map((item, index) => (
                    <motion.div
                    key={index}
                    className="p-6 rounded-lg shadow-lg bg-white transition-transform duration-300 hover:shadow-xl"
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                    whileHover={{ scale: 1.05 }} // Увеличение карточки при наведении
                    >
                    <div className="text-5xl font-bold mb-4 text-center" style={{ color: '#3e3e64' }}>{item.number}</div>
                    <h3 className="font-semibold text-lg mb-4 py-2 rounded-lg text-white shadow-md" style={{ backgroundColor: '#A1A1C1', fontWeight: 700 }}>
                        {item.title}
                    </h3>
                    <p className="font-light text-gray-700">{item.text}</p>
                    </motion.div>
                ))}
                </div>
            </div>
            </section>
            <section className="flex flex-col justify-center items-center lg:flex-row py-12">
                <img src="./assets/form.jpeg" alt="" className="w-full lg:w-1/2 max-w-md rounded-lg shadow-lg transition-transform duration-300 hover:scale-105" />
                <div className="lg:w-1/3 lg:ml-12 p-6 bg-white rounded-lg shadow-md">
                    <h1 className="text-5xl font-medium lg:text-left text-center mb-4" style={{ color: '#4A4A4A' }}>НАШИ УСЛУГИ</h1>
                    <p className="text-xl text-center lg:text-left font-light text-gray-700">
                    Заполните небольшую форму, и мы поможем вам рассчитать стоимость проекта
                    </p>
                    <div className="text-center lg:text-left mt-4">
                    <button onClick={openPopup} className="inline-block text-white font-semibold border px-14 py-2 rounded-full transition-transform duration-300 hover:scale-105" style={{ background: '#3e3e64', borderColor: '#A1A1C1' }}>
                        Рассчитать стоимость
                    </button>
                    </div>
                </div>
            </section>
            
            <section className="py-20 bg-gray-100">
                <div className="container mx-auto">
                <h2 className="text-5xl font-semibold text-center mb-12" style={{ color: '#4A4A4A' }}>Этапы ремонта</h2>
                    <div className="relative">
                    
                    <div className="flex justify-between items-center relative mt-4">
                        {/* 1 */}
                        <div className="flex flex-col items-center relative ml-8">
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">1</div>
                            <h4 className="font-medium text-center mt-16">Свяжитесь с нами и оставьте заявку</h4>
                            <div className="my-auto absolute top-1/2 w-1 h-7 bg-gray-300"></div>
                        </div>
                        {/* 2 */}
                        <div className="flex flex-col items-center relative">
                            <h4 className="font-medium text-center mb-16">Подробная смета объекта</h4>
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">2</div>
                            <div className="my-auto absolute bottom-1/2 w-1 h-7 bg-gray-300 "></div>
                        </div>
                        {/* 3 */}
                        <div className="flex flex-col items-center relative">
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">3</div>
                            <h4 className="font-medium text-center mt-16">Составление и подпись договора</h4>
                            <div className="my-auto absolute top-1/2 w-1 h-7 bg-gray-300"></div>
                        </div>

                        <div className="mx-auto absolute top-1/2 w-full h-1 bg-gray-300"></div>

                        {/* 4 */}
                        <div className="flex flex-col items-center relative">
                            <h4 className="font-medium text-center mb-16">Закупка материалов и инструментов</h4>
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">4</div>
                            <div className="my-auto absolute bottom-1/2 w-1 h-7 bg-gray-300"></div>
                        </div>
                        {/* 5 */}
                        <div className="flex flex-col items-center relative">
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">5</div>
                            <h4 className="font-medium text-center mt-16">Ремонтные работы</h4>
                            <div className="my-auto absolute top-1/2 w-1 h-7 bg-gray-300"></div>
                        </div>
                        {/* 6 */}
                        <div className="flex flex-col items-center relative mr-8">
                            <h4 className="font-medium text-center mb-16">Сдача работы и оплата услуг</h4>
                            <div className="w-16 h-16 bg-gray-700 font-medium text-2xl text-white rounded-full flex items-center justify-center">6</div>
                            <div className="my-auto absolute bottom-1/2 w-1 h-7 bg-gray-300"></div>
                        </div>
                    </div>
                    </div>
                </div>
                </section>
                <section className="py-20 ">
      <div className="container mx-auto">
        <h2 className="text-5xl font-medium text-center mb-12" style={{ color: '#4A4A4A' }}>Специальные предложения</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {offers.map((offer, index) => (
            <motion.div
              key={index}
              className="p-8 rounded-lg shadow-lg cursor-pointer"
              style={{ backgroundColor: offer.bgColor }}
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            >
              <img src={offer.image} alt={offer.title} className="mb-4 w-full h-48 object-cover rounded-lg" />
              <h3 className="text-2xl font-semibold text-white mb-4">{offer.title}</h3>
              <p className="text-lg font-light text-white mb-4">{offer.description}</p>
              <button
                onClick={() => openModal(offer)}
                className="inline-block text-white font-semibold border px-8 py-2 rounded-full hover:text-white transition"
                style={{ background: '#3e3e64', borderColor: '#3e3e64' }}
              >
                Подробнее
              </button>
            </motion.div>
          ))}
        </div>

        {/* Модальное окно для подробной информации */}
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Подробнее о предложении"
        >
          {selectedOffer && (
            <>
              <h2 className="text-3xl font-medium mb-4" style={{ color: '#67582E' }}>{selectedOffer.title}</h2>
              <img src={selectedOffer.image} alt={selectedOffer.title} className="mb-4 w-full h-48 object-cover rounded-lg" />
              <p className="text-lg mb-4">{selectedOffer.longDescription}</p>
              <button
                onClick={closeModal}
                className="inline-block text-white font-semibold border px-8 py-2 rounded-full hover:text-white transition"
                style={{ background: '#3e3e64', borderColor: '#3e3e64' }}
              >
                Закрыть
              </button>
            </>
          )}
        </Modal>
      </div>
    </section>
    {isOpen && <Summary handleClose={closePopup} />}
    <div style={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 100 }}>
                <a href="https://api.whatsapp.com/send?phone=87788338640" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/whatsapp.png" alt="WhatsApp" style={{ width: '50px', height: '50px' }} />
                </a>
            </div>
           
        </div>
    );
};

export default Main;
