import React,{ useState }  from 'react';
import { FaDollarSign, FaTools } from 'react-icons/fa';
import Summary from "../components/Summary";
import Modal from 'react-modal';

const Prices = () => {
    const [isOpen, setIsOpen] = useState(false);

    const openPopup = () => {
        setIsOpen(true);
    };

    const closePopup = () => {
        setIsOpen(false);
    };
  const prices = [
    {
      category: 'Отделочные работы',
      items: [
        { name: 'Затирка швов кафельной плитки', unit: 'кв. м.', price: 337.00 },
        { name: 'Окраска стен (1 слой)', unit: 'кв. м.', price: 270.00 },
        { name: 'Устройство перегородок из ГКЛ с звукоизоляцией', unit: 'кв. м.', price: 3358.00 },
        { name: 'Устройство перегородок из ГКЛ (2 сторона)', unit: 'кв. м.', price: 2568.00 },
        { name: 'Устройство стен из ГКЛ (1 сторона)', unit: 'кв. м.', price: 1344.00 },
        { name: 'Нанесение жидких обоев', unit: 'кв. м.', price: 1750.00 },
        { name: 'Флизелиновых обоев', unit: 'кв. м.', price: 709.00 },
        { name: 'Обои под покраску', unit: 'кв. м.', price: 642.00 },
        { name: 'Поклейка обоев (бумажных)', unit: 'кв. м.', price: 554.00 },
        { name: 'Облицовка плиткой мозаикой', unit: 'кв. м.', price: 450.00 },
        { name: 'Устройство бордюра керамической плитки', unit: 'п. м.', price: 450.00 },
        { name: 'Укладка поверхности натуральным камнем', unit: 'кв. м.', price: 2680.00 },
        { name: 'Укладка керамической плиткой', unit: 'кв. м.', price: 3102.00 },
        { name: 'Высококачественная штукатурка стен', unit: 'кв. м.', price: 1736.00 },
        { name: 'Улучшенная штукатурка стен', unit: 'кв. м.', price: 1209.00 },
        { name: 'Простая штукатурка стен', unit: 'кв. м.', price: 1061.00 },
        { name: 'Грунтовка стен (1 слой)', unit: 'кв. м.', price: 209.00 },
        { name: 'Шпаклевка стен под обои (1 слой)', unit: 'кв. м.', price: 337.00 },
        { name: 'Шпаклевка стен под окраску (1 слой)', unit: 'кв. м.', price: 574.00 },
        { name: 'Облицовка стен “Сайдингом” с мет. Каркасом', unit: 'кв. м.', price: 1851.00 },
      ],
    },
    {
      category: 'Работы по устройству полов',
      items: [
        { name: 'Укладка керамической плитки', unit: 'кв. м.', price: 1959.00 },
        { name: 'Настил коврового покрытия', unit: 'кв. м.', price: 473.00 },
        { name: 'Устройство пола из керамогранита', unit: 'кв. м.', price: 2568.00 },
        { name: 'Устройство пола из природного камня', unit: 'кв. м.', price: 3845.00 },
        { name: 'Устройство черновых полов из ДСП, влагостойкой фанера', unit: 'кв. м.', price: 1148.00 },
        { name: 'Настил штучного паркета', unit: 'кв. м.', price: 4663.00 },
        { name: 'Настил линолиума', unit: 'кв. м.', price: 473.00 },
        { name: 'Устройство стяжки из ПЦР толщина 30-150 мм', unit: 'кв. м.', price: 1851.00 },
        { name: 'Настил ламината', unit: 'кв. м.', price: 1013.00 },
        { name: 'Покраска поверхности пола (1 слой)', unit: 'кв. м.', price: 1822.00 },
        { name: 'Штукатурка (слой до 2см)', unit: 'кв. м.', price: 1014.00 },
        { name: 'Шпаклевка под побелку, покраску (2 слоя)', unit: 'кв. м.', price: 1015.00 },
      ],
    },
    {
      category: 'Сантехнические работы',
      items: [
        { name: 'Установка Унитаза “Компакт”', unit: 'шт.', price: 4054.00 },
        { name: 'Установка ванны', unit: 'шт.', price: 5406.00 },
        { name: 'Раковины на пьедестале', unit: 'шт.', price: 4054.00 },
        { name: 'Установка писсуара', unit: 'шт.', price: 3379.00 },
        { name: 'Установка биде', unit: 'шт.', price: 4730.00 },
        { name: 'Установка Настенного унитаза', unit: 'шт.', price: 6400.00 },
        { name: 'Подключение фильтров тонкой очистки', unit: 'шт.', price: 4054.00 },
        { name: 'Установка водонагреватель накопительный', unit: 'шт.', price: 7433.00 },
        { name: 'Установка радиаторы отопления', unit: 'шт.', price: 10137.00 },
        { name: 'Установка полотенцесушителя', unit: 'шт.', price: 6800.00 },
        { name: 'Установка Водосчетчика', unit: 'шт.', price: 4122.00 },
        { name: 'Установка смесителя', unit: 'шт.', price: 3041.00 },
        { name: 'Подключение стиральной машины', unit: 'шт.', price: 4730.00 },
      ],
    },
    {
      category: 'Строительные работы',
      items: [
        { name: 'Кладка кирпичных перегородок', unit: 'кв. м.', price: 2560.00 },
        { name: 'Кладка стен из гипсоблоков', unit: 'куб. м.', price: 4074.00 },
      ],
    },
    {
      category: 'Установка дверей',
      items: [
        { name: 'Устройство откоса арок из ГКЛ овал', unit: 'п. м.', price: 2290.00 },
        { name: 'Устройство откоса арок из ГКЛ угол 90гр', unit: 'п. м.', price: 1520.00 },
        { name: 'Устройство дверного откоса из ГКЛ', unit: 'п. м.', price: 2892.00 },
        { name: 'Установка порога', unit: 'шт.', price: 855.00 },
        { name: 'Монтаж дверного блока без фурнитуры', unit: 'шт.', price: 3919.00 },
        { name: 'Монтаж металлической двери (стандартный проем)', unit: 'шт.', price: 8380.00 },
      ],
    },
    {
      category: 'Устройство потолков',
      items: [
        { name: 'Устройство потолочного плинтуса (пластик)', unit: 'п. м.', price: 50.00 },
        { name: 'Устройство потолочного плинтуса (гипсовый багет)', unit: 'п. м.', price: 30.00 },
        { name: 'Устройство потолков из пластиковых панелей', unit: 'кв. м.', price: 330.00 },
        { name: 'Наклейка обоев на потолок', unit: 'кв. м.', price: 85.00 },
        { name: 'Устройство потолков из ГКЛ, ГВЛ', unit: 'кв. м.', price: 278.00 },
      ],
    },
  ];


  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row items-center mb-10">
          <div className="lg:w-1/2 p-4">
            <h2 className="text-5xl font-medium" style={{ color: '#3e3e64' }}>Хотите узнать точную стоимость ремонта? </h2> <h2 className="text-5xl font-medium mb-4" style={{ color: '#A1A1C1' }}>Используйте калькулятор и получите смету!</h2>
            <p className="text-lg font-light mb-6">
              Мы предлагаем широкий спектр отделочных и строительных работ по доступным ценам. 
              Ниже представлены расценки на наши услуги. Для получения точной цены, пожалуйста, свяжитесь с нами.
            </p>
            <button onClick={openPopup} className="inline-block px-6 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 transition" style={{ background: '#3e3e64', borderColor: '#A1A1C1' }}>
                Рассчитать стоимость
            </button>
          </div>
          <div className="lg:w-1/2 p-4">
            <img src="/assets/home11.jpeg" alt="Цены на услуги" className="w-full rounded-lg shadow-lg" />
          </div>
          
        </div>
        
        {prices.map(category => (
          <div key={category.category} className="mb-10 ">
            <h3 className="text-3xl font-medium mb-4">{category.category}</h3>
            <table className="min-w-full border-collapse border border-gray-900 shadow-lg rounded-lg overflow-hidden">
              <thead>
                <tr className="bg-[#3e3e64]">
                  <th className="border border-gray-400 px-4 py-2 text-left text-gray-900">Наименование</th>
                  <th className="border border-gray-400 px-4 py-2 text-left text-gray-900">Ед. изм.</th>
                  <th className="border border-gray-400 px-4 py-2 text-left text-gray-900">Цена ₸</th>
                </tr>
              </thead>
              <tbody>
                {category.items.map(item => (
                  <tr key={item.name} className="hover:bg-purple-100 transition duration-200">
                    <td className="border font-light border-gray-400 px-4 py-2">{item.name}</td>
                    <td className="border font-light border-gray-400 px-4 py-2">{item.unit}</td>
                    <td className="border font-light border-gray-400 px-4 py-2">{item.price.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
        <div className="mt-10 text-center font-light text-gray-900">
          <p>* Расценки не применяются при сложности работ и малых объемах</p>
          <p>* При ограничении времени применяется коэффициент</p>
          <p>* Не является публичной офертой</p>
          <p>* Узнать точную цену работ можно только по записи на замер</p>
        </div>
      </div>
      {isOpen && <Summary handleClose={closePopup} />}
    </section>
  );
};

export default Prices;
