import React, { useState } from 'react';

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    const handleQuestionClick = (question) => {
        alert(`Вы выбрали: ${question}`);
    };

    return (
        <div>
            <div style={{ position: 'fixed', bottom: '80px', right: '30px', zIndex: 100 }}>
                <button 
                    onClick={toggleChat} 
                    className="bg-purple-900 text-white rounded-full p-3 shadow-lg hover:shadow-xl transition"
                >
                    {isOpen ? 'Закрыть' : 'Чат'}
                </button>
                {isOpen && (
                    <div className="bg-white shadow-lg rounded-lg p-4 mt-2 transition-transform" style={{ width: '300px' }}>
                        <h2 className="font-bold text-lg mb-2">Чат с нами</h2>
                        <p className="text-sm mb-2">Выберите вопрос:</p>
                        <div className="flex flex-col">
                            <button 
                                onClick={() => handleQuestionClick('Как заказать услугу?')} 
                                className="bg-purple-900 text-white rounded px-3 py-2 mb-2 hover:bg-purple-800 transition"
                            >
                                Как заказать услугу?
                            </button>
                            <button 
                                onClick={() => handleQuestionClick('Какие способы оплаты доступны?')} 
                                className="bg-purple-900 text-white rounded px-3 py-2 mb-2 hover:bg-purple-800 transition"
                            >
                                Какие способы оплаты доступны?
                            </button>
                            <button 
                                onClick={() => handleQuestionClick('Сколько времени занимает ремонт?')} 
                                className="bg-purple-900 text-white rounded px-3 py-2 mb-2 hover:bg-purple-800 transition"
                            >
                                Сколько времени занимает ремонт?
                            </button>
                            <button 
                                onClick={() => handleQuestionClick('Где вы находитесь?')} 
                                className="bg-purple-900 text-white rounded px-3 py-2 mb-2 hover:bg-purple-800 transition"
                            >
                                Где вы находитесь?
                            </button>
                        </div>
                    </div>
                )}
            </div>
            <div style={{ position: 'fixed', bottom: '30px', right: '30px', zIndex: 100 }}>
                <a href="https://api.whatsapp.com/send?phone=87788338640" target="_blank" rel="noopener noreferrer">
                    <img src="./assets/whatsapp.png" alt="WhatsApp" style={{ width: '50px', height: '50px' }} />
                </a>
            </div>
        </div>
    );
};

export default Chatbot;
