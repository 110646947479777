import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Main from './pages/Main';
import NavBar from './components/NavBar';
import Footer from './components/Footer';
import Prices from './pages/Prices';
import Gallery from './pages/Gallery';
import Review from './pages/Review';
import List from './pages/List';
import Blog from './pages/Blog';

function App() {
  return (
    <Router>
      <div className="App">
        <NavBar />
        <div className="content" style={{ paddingTop: '60px' }}>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/list" element={<List />} />
            <Route path="/prices" element={<Prices />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/review" element={<Review />} />
            <Route path="/blog" element={<Blog />} />
          </Routes>
        </div>
          <Footer/>
      </div>
    </Router>
  );
}

export default App;
