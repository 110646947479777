import React from 'react';
import { Link } from 'react-router-dom';

const Blog = () => {
  const blogPosts = [
    {
      id: 1,
      title: 'Как рассчитать стоимость ремонта?',
      excerpt: 'Сколько будет стоить ремонт? Какой бюджет закладывать? В нашей статье 5 ошибок в ремонте квартир мы поставили не составление бюджета первой из ошибок. Сразу вас уверим, что стоимость ремонта зависит',
      date: '2024-09-20',
      image: '/assets/blog1.webp',
      link: 'https://xn----7sbbbfu2bmjjsb4ad0l.xn--p1ai/articles/331-kak-rasschitat-stoimost-remonta.html', 
    },
    {
      id: 2,
      title: 'Варианты отделки стен – какая лучше?',
      excerpt: 'Чтобы интерьер смотрелся современно и не требовал постоянного ремонта, необходимо грамотно подойти к выбору отделочных материалов для стен. Сравниваем плюсы и минусы, от обоев до декоративной штукатурки.',
      date: '2024-03-05',
      image: '/assets/blog2.jpeg',
      link: 'https://www.prorabneva.ru/blog/stati-o-remonte/varianty-otdelki-sten', 
    },
    {
      id: 3,
      title: 'Отделка ванной комнаты плиткой: советы и идеи дизайна',
      excerpt: 'Современные варианты плитки отличаются прочностью, широким выбором дизайнов, поэтому по праву считаются наиболее востребованными при отделке ванных комнат.',
      date: '2024-08-08',
      image: '/assets/blog3.webp',
      link: 'https://idetremont.ru/articles/otdelka-vannoy-komnaty-plitkoy/', 
    },{
        id: 4,
        title: 'Интерьер в стиле хюгге. Основы воссоздания интерьера.',
        excerpt: 'Хюгге или фен-шуй по скандинавски Hygge - это лингвоспецифическое слово у которого нет буквального перевода, чаще всего это понятие переводят как: уют, который делает тебя счастливым. Звучит красиво, не правда ли?.',
        date: '2024-12-14',
        image: '/assets/blog4.jpeg',
        link: 'https://art-remont.ru/blog/stil-hyugge', 
      },
  ];

  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-5xl font-medium text-left mb-4" style={{ color: '#3e3e64' }}>
          Наш Блог
        </h2>
        <div className="flex flex-col space-y-8">
          {blogPosts.map((post) => (
            <div key={post.id} className="bg-white p-6 rounded-lg shadow-lg flex">
              <img src={post.image} alt={post.title} className="w-1/3 h-64 object-cover rounded-lg mr-4" />
              <div className="flex-1">
                <h3 className="text-xl font-semibold mb-2">{post.title}</h3>
                <p className="text-gray-500 mb-2">{post.date}</p>
                <p className="text-lg font-light mb-4">{post.excerpt}</p>
                <Link to={post.link} className="bg-purple-900 font-semibold text-white px-4 py-2 rounded-lg hover:bg-purple-800 transition duration-300">
                  Читать дальше
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
