import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const List = () => {
  const servicesData = [
    {
      id: 1,
      title: 'Отделка стен',
      image: '/assets/service1.jpeg',
    },
    {
      id: 2,
      title: 'Ремонт ванной комнаты',
      image: '/assets/service2.jpeg',
    },
    {
      id: 3,
      title: 'Укладка плитки',
      image: '/assets/service3.jpeg',
    },
    {
      id: 4,
      title: 'Монтаж потолков',
      image: '/assets/service5.jpeg',
    },
    {
      id: 5,
      title: 'Дизайн интерьера',
      image: '/assets/service4.jpeg',
    },
    {
      id: 6,
      title: 'Установка дверей',
      image: '/assets/service6.jpeg',
    },
  ];
  const reviewsData = [
    {
      id: 1,
      name: 'Жанара',
      review: 'Отличная работа! Все выполнено качественно.',
    },
    {
      id: 2,
      name: 'Айгуль',
      review: 'Превосходный сервис и замечательные работники!',
    },
    {
      id: 3,
      name: 'Смирнова Д.С.',
      review: 'Работы выполнены в срок и на высоком уровне.',
    },
  ];

  return (
    <section className="py-20 bg-gray-100">
      <div className="container mx-auto">
        <h2 className="text-5xl font-medium text-center mb-8" style={{ color: '#3e3e64' }}>
          Наши Услуги
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-8">
          {servicesData.map((service) => (
            <motion.div
              key={service.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden"
              initial={{ scale: 1 }}
              whileHover={{ scale: 1.05 }}
              transition={{ type: 'spring', stiffness: 300 }}
            >
              <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
              <div className="p-4">
                <h3 className="text-xl font-semibold text-center">{service.title}</h3>
              </div>
            </motion.div>
          ))}
        {/* Карточка для оставления отзыва */}
        </div>

        <h2 className="text-3xl font-medium text-center my-16" style={{ color: '#3e3e64' }}>
          Отзывы наших клиентов
        </h2>
        <div className="flex overflow-x-auto space-x-4">
          {reviewsData.map((review) => (
            <div 
            key={review.id} 
            className="bg-white-100 p-4 rounded-lg shadow-lg w-60 flex-shrink-0 border-2 border-purple-900"
          >
            <h3 className="text-lg font-semibold">{review.name}</h3>
            <p className="text-md my-4 font-light">{review.review}</p>
            <Link to="/review" className="text-purple-900 font-bold hover:underline mt-2 block">
              Читать далее
            </Link>
          </div>
          
          ))}

            <div className="relative bg-cover bg-center h-64 w-1/4 mx-2" style={{ backgroundImage: "url('./assets/home8.jpeg')" }}>
                <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                <Link to="/review">
                    <button className="px-4 py-2 bg-purple-900 text-white rounded-lg hover:bg-purple-800">
                    Оставить отзыв
                    </button>
                </Link>
                </div>
            </div>

        </div>
      </div>
    </section>
  );
};

export default List;
